
import { defineComponent, onMounted, PropType, ref } from "vue";

import CarsSvc from "@/assets/js/carsSvc";
import CarModel from "../carDisplay/CarModel";
import SelectMakeModelModel from "./SelectMakeModelModel";

export default defineComponent({
	name: "SelectMakeModel",
	emits: ["selectMakeModel"],
	props: {
		preselectedCar: {
			type: Object as PropType<CarModel>
		}
	},
	setup(props, context) {
		const makes = ref<string[]>([]);
		const models = ref<string[]>([]);
		const trims = ref<string[]>([]);

		const selectMakeModel = ref<SelectMakeModelModel>({
			make: null,
			model: null,
			trim: null
		});

		const setPreselectedCar = (car: CarModel) => {
			selectMakeModel.value.make = car.make;
			selectMakeModel.value.model = car.model;
			selectMakeModel.value.trim = car.trim;
		};
		const getMakes = async () => {
			makes.value = await CarsSvc.getMakes();
		};
		const getModels = async () => {
			trims.value = [];
			models.value = await CarsSvc.getModels(selectMakeModel.value.make as string);

			if (
				!props.preselectedCar ||
				(props.preselectedCar && props.preselectedCar.make != selectMakeModel.value.make) ||
				!props.preselectedCar.in_production
			) {
				selectMakeModel.value.model = null;
				selectMakeModel.value.trim = null;
			}
		};
		const getTrims = async () => {
			trims.value = await CarsSvc.getTrims(selectMakeModel.value.make as string, selectMakeModel.value.model as string);

			if (
				!props.preselectedCar ||
				(props.preselectedCar && props.preselectedCar.model != selectMakeModel.value.model) ||
				!props.preselectedCar.in_production
			) {
				selectMakeModel.value.trim = trims.value[0] || null;
				trimSelected();
			}
		};
		const trimSelected = () => {
			context.emit("selectMakeModel", selectMakeModel.value);
		};

		onMounted(async () => {
			await getMakes();

			if (props.preselectedCar) {
				setPreselectedCar(props.preselectedCar); // this needs to happen before get()s below

				await getModels();
				await getTrims();
			}
		});

		return {
			selectMakeModel,
			makes,
			models,
			getModels,
			trims,
			getTrims,
			trimSelected
		};
	}
});
